import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";


import Sidebar from "../components/core/Dashboard/Sidebar";
import Sidebar2 from "../components/core/Dashboard/Sidebar2";

function Dashboard() {
  const { loading: profileLoading } = useSelector((state) => state.profile);
  const { loading: authLoading } = useSelector((state) => state.auth);



  if (profileLoading || authLoading) {
    return (
      <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <>
      <div className="relative flex min-h-[calc(100vh-3.5rem)] ">
        <div className='flex '>
          <Sidebar2/>
        </div>


        <div className="h-[calc(100vh-3.5rem)] flex-1 overflow-auto">
          <div className="mx-auto w-11/12 max-w-[1000px] py-10">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
