import React from 'react'

const Error = () => {
  return (
    <>
    <div className='flex justify-center items-center text-3xl text-white'>
      Error - 404 Not found
    </div>
    </>
  )
}

export default Error
