// CookiePolicyData.js

const CookiePolicyData = [
    {
      id: 1,
      title: 'What Are Cookies?',
      content: [
        'Cookies are small pieces of data stored on your device (computer or mobile device).',
        'They are used to remember your preferences and provide a personalized experience.',
      ],
    },
    {
      id: 2,
      title: 'How We Use Cookies',
      content: [
        'We use cookies for authentication, tracking, and analyzing user behavior.',
        'Third-party services integrated into our website may also use cookies.',
      ],
    },
    {
      id: 3,
      title: 'Types of Cookies We Use',
      content: [
        'Session Cookies: These are temporary cookies that are erased when you close your browser.',
        'Persistent Cookies: These cookies remain on your device for a longer period.',
      ],
    },
    {
      id: 4,
      title: 'Your Cookie Choices',
      content: [
        'You can control and manage cookies in your browser settings.',
        'However, disabling certain cookies may affect the functionality of our website.',
      ],
    },
    {
      id: 5,
      title: 'Performance Cookies',
      content: [
        'These cookies collect information about how visitors use our website.',
        'They help us understand which pages are popular and how users navigate the site.',
      ],
    },
    {
      id: 6,
      title: 'Functionality Cookies',
      content: [
        'Functionality cookies enable our website to remember choices you make.',
        'They enhance your experience by providing personalized features.',
      ],
    },
    {
      id: 7,
      title: 'Targeting or Advertising Cookies',
      content: [
        'These cookies are used to deliver ads relevant to your interests.',
        'They may be placed by advertising networks with our permission.',
      ],
    },
    {
      id: 8,
      title: 'E-Learning Analytics Cookies',
      content: [
        'In an e-learning context, analytics cookies track user interactions with learning content.',
        'They help instructors improve courses and enhance the learning experience.',
      ],
    },
    {
      id: 9,
      title: 'Social Media Cookies',
      content: [
        'Our website may include social media features that use cookies.',
        'These features may collect your IP address and which page you are visiting on our site.',
      ],
    },
    {
      id: 10,
      title: 'Cookies and Personal Information',
      content: [
        'We do not use cookies to collect personal information that identifies you.',
        'Your personal information is handled according to our Privacy Policy.',
      ],
    },
  ];
  
  export default CookiePolicyData;
  