import React from "react";
import { useEffect, useState } from "react";
import { VscAdd } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import user_logo from "../../../assets/user.png";

import { getAllStudentDetails } from "../../../services/operations/adminAPI";
import IconBtn from "../../common/IconBtn";
import AdminCoursesTable from "./InstructorCourses/AdminCoursesTable";
function AllStudents() {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [studentDetails, setStudentDetails] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      const result = await getAllStudentDetails();
      if (result) {
        setStudentDetails(result);
      }
    };
    fetchCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="mb-14 flex items-center justify-between">
        <h1 className="text-3xl font-medium text-richblack-5">
          All Student Details
        </h1>
        <IconBtn text="Add Students" onclick={() => navigate("")}>
          <VscAdd />
        </IconBtn>
      </div>

      <Table className="rounded-xl border border-richblack-800">
      <Thead>
          <Tr className="flex gap-x-10 rounded-t-md border-b border-b-richblack-800 px-6 py-2">
            <Th className="flex-1 text-left text-sm font-medium uppercase text-richblack-100">
              Students
            </Th>

            <Th className=" mr-[10%]  text-center ml-4 text-sm font-medium uppercase text-richblack-100 ">
              ACTIVE
            </Th>
            <Th className=" mr-[7%] text-sm font-medium uppercase text-richblack-100">
              APPROVED
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {studentDetails &&
            studentDetails.map((temp, index) => {
              return (
                <>
                  <Tr
                    key={temp._id}
                    className="flex gap-x-10 border-b border-richblack-800 px-6 py-8"
                  >
                    <Td className="flex flex-1 gap-x-2">
                      <img
                        src={temp.image != "/" ? temp.image : user_logo}
                        alt="student"
                        className="h-[150px] w-[150px] rounded-[100%] "
                      />
                      <div className="flex flex-col justify-between">
                        <p className="text-lg font-semibold text-richblack-5">
                          <div>
                            <p>{temp.firstName + " " + temp.lastName}</p>
                            <p className="text-sm font-normal"> {temp.email}</p>

                            <p className="text-sm font-normal">
                              gender:{" "}
                              {temp.additionalDetails.gender
                                ? temp.additionalDetails.gender
                                : "Not define"}
                            </p>
                            <p className="text-sm font-normal">
                              Mobile No:{" "}
                              {temp.additionalDetails.contactNumber
                                ? temp.additionalDetails.contactNumber
                                : "No Data"}
                            </p>
                            <p className="text-sm font-normal">
                              DOB:{" "}
                              {temp.additionalDetails.dateOfBirth
                                ? temp.additionalDetails.dateOfBirth
                                : "No Data"}
                            </p>
                          </div>
                        </p>
                      </div>
                    </Td>
                    <Td className="mr-[11.5%]   text-sm font-medium text-richblack-100">
                      {temp.active ? "Active" : "Inactive"}
                    </Td>
                    <Td className="mr-[8%] text-sm font-medium text-richblack-100">
                      {temp.approved ? "Approved" : "Not Approved"}
                    </Td>
                  </Tr>

                  <Tr className="ml-10 flex gap-x-10 border-b border-richblack-800 px-6 py-8">
                    {temp.courses.map((course) => (
                      <div className="text-white" key={course._id}>
                        <Tr>
                          <p className="text-white mr-4">
                            Show all Enrolled Courses
                          </p>
                        </Tr>
                        <p>{course.courseName}</p>
                        <p className="text-sm font-normal">
                          Price: ₹{course.price}
                        </p>
                      </div>
                    ))}
                  </Tr>
                </>
              );
            })}
        </Tbody>
      </Table>
      {studentDetails && false && (
        <pre className="text-white">
          {JSON.stringify(studentDetails, null, 2)}
        </pre>
        // <AdminCoursesTable courses={courses} setCourses={setCourses} />
      )}
    </div>
  );
}

export default AllStudents;
