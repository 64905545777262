import React from "react"

import Footer from "../components/common/Footer"
import ContactDetails from "../components/ContactPage/ContactDetails"
import ContactForm from "../components/ContactPage/ContactForm"
import ReviewSlider from "../components/common/ReviewSlider"
import Ban from '../assets/ban.png'
import CustomerService from '../assets/customer-service.png'

const Contact = () => {
  return (
    <div>

      <div className="hidden ms:static h-96 bg-gray-400 border-dashed border-2 border-yellow-500 text-white p-4 relative">


        <div className="flex-1 mb-4 float-left">
          <p className="text-lg pt-11 font-bold text-5xl bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] bg-clip-text text-4xl font-semibold text-transparent lg:w-[70%] mb-4 pt-20 ">Get in Touch</p>
          <p className="text-3xl">
          And we would love to show you our Ridiculous <br />Commitment to Learning.
          </p>
        </div>
        <div className="absolute top-0 right-0">
          <img src={Ban} alt="Banner" className="w-full pr-24 h-auto" />
        </div>

        <div className="absolute top-80 content-center left-80 bg-gradient-to-r from-pink-400 to-yellow-600 max-h-17 w-68 p-4 rounded">

          {/* Contact information */}
          <div className="flex items-center">
            <img src={CustomerService} alt="Banner" className="w-14 h-14 mr-4" />

            <div>
              <p className="font-bold text-lg">Your Project Code, NEED SOME HELP? REACH US AT :</p>
              <p className="text-base">
                Phone (India): +91 6362 720 677
                <br />
                info@ricecity.in
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto mt-20 flex w-11/12 max-w-maxContent flex-col justify-between gap-10 text-white lg:flex-row">
        {/* Contact Details */}
        <div className="lg:w-[40%]">
          <ContactDetails />
        </div>

        {/* Contact Form */}
        <div className="lg:w-[60%]">
          <ContactForm />
        </div>
      </div>
      <div className="relative mx-auto my-20 flex w-11/12 max-w-maxContent flex-col items-center justify-between gap-8 bg-richblack-900 text-white">
        {/* Reviws from Other Learner */}
        <h1 className="text-center text-4xl font-semibold mt-8">
          Reviews from other learners
        </h1>
        <ReviewSlider />
      </div>
      <Footer />
    </div>
  )
}

export default Contact