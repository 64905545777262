import * as React from "react";
import { useRef, useState } from "react";

import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { NavbarLinks } from "./../../../data/navbar-links";
import { AiOutlineMenu } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { PiSignInFill } from "react-icons/pi";
import { FiLogIn, FiLogOut } from "react-icons/fi";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { logout } from "../../../services/operations/authAPI";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
// import useOnClickOutside from "../../../hooks/useOnClickOutside";

//  icons
// import { IoHome } from "react-icons/io5";
// import { PiNoteFill } from "react-icons/pi";
// import { MdEmail } from "react-icons/md";
// import { TbCategory } from "react-icons/tb";

const NavbarLinks2 = [
  {
    title: "Sign Up",
    icon: FiLogIn,
    path: "/signup",
  },
  {
    title: "Log In",
    icon: FiLogIn,
    path: "/login",
  },
];
const NavbarLinks3 = [
  {
    title: "Dashboard",
    icon: FiLogIn,
    path: "/dashboard/my-profile",
  },
];

export default function MobileSidebarMenu({ subLinks }) {
  const location = useLocation();
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const ref = useRef(null);

  useOnClickOutside(ref, () => setOpen(false));

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const CatalogTab = ({ category }) => (
    <Link to={`/catalog/${category.name.split(" ").join("-").toLowerCase()}`}>
      <ListItem key={category._id} disablePadding>
        <div
          className={`${
            location.pathname ===
            `/catalog/${category.name.split(" ").join("-").toLowerCase()}`
              ? "bg-yellow-700 text-yellow-25"
              : "text-white"
          } 
                            flex gap-10 items-center px-5 py-2 mx-2 w-full rounded-xl `}
        >
          <p className="truncate ml-[60px] ">
            <KeyboardArrowRightIcon /> {category.name}
          </p>
        </div>
      </ListItem>
    </Link>
  );

  const CatalogList = ({ subLinks }) =>
    subLinks && (
      <List>
        {subLinks.map((category, index) => (
          <CatalogTab key={index} category={category} />
        ))}
      </List>
    );

  const Tab = ({ link }) => {
    return (
      <Link to={link?.path}>
        <ListItem key={link.title} disablePadding>
          <div
            className={`${
              location.pathname === link.path
                ? "bg-yellow-700 text-yellow-25"
                : "text-white"
            } 
                                        flex gap-10 items-center px-5 py-2 mx-2 w-full rounded-xl `}
          >
            <p className="text-yellow-25 text-2xl ">{<link.icon />}</p>
            <ListItemText primary={link.title} />
          </div>
        </ListItem>
      </Link>
    );
  };

  // main list
  const list = (anchor) => (
    <Box
      //   sx={{ width: 250, marginTop: 6.5 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="bg-[#222831] h-screen w-screen text-white "
    >
      <div className="bg-[#161d29] pt-2 pb-[45px] pr-2 ">
        <Button className="float-right ">
          <RxCross2 fontSize={24} fill="#AFB2BF" />
        </Button>
      </div>
      <List>
        {NavbarLinks.map((link, index) => (
          <React.Fragment key={index}>
            <Tab link={link} />
            {link.title === "Catalog" && <CatalogList subLinks={subLinks} />}
          </React.Fragment>
        ))}
      </List>
      <Divider />
      {/* signIn - logIn buttons - Dashboard  */}
      <List>
        {NavbarLinks2.map(
          (link, index) => token === null && <Tab key={index} link={link} />
        )}
        {NavbarLinks3.map(
          (link, index) => token != null && <Tab key={index} link={link} />
        )}
      </List>{" "}
      <Divider />
      {token != null && (
        <>
          {/* <div className=" mb-2 text-white flex gap-10 items-center px-5 mx-2 w-full rounded-xl ">
            <p className="text-yellow-25 text-2xl ">{<FiLogIn />}</p>
            <ListItemText primary="Dashboard" />
          </div> */}
          <List
            onClick={() => {
              dispatch(logout(navigate));
              setOpen(false);
            }}
          >
            <div className=" mb-2 text-white flex gap-10 items-center px-5  mx-2 w-full rounded-xl ">
              <p className="text-yellow-25 text-2xl ">{<FiLogOut />}</p>
              <ListItemText primary="Logout" />
            </div>
          </List>
        </>
      )}
    </Box>
  );

  return (
    <div className="md:hidden my-2 x-10">
      <React.Fragment>
        <Button onClick={toggleDrawer("right", true)}>
          <AiOutlineMenu fontSize={24} fill="#AFB2BF" />
        </Button>

        <div className="">
          <SwipeableDrawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
            className=""
          >
            {list("right")}
          </SwipeableDrawer>{" "}
        </div>
      </React.Fragment>
    </div>
  );
}
