import React from "react"


// Import Swiper styles
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/pagination"
import './styles.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

import Course_Card from "./Course_Card"


// course loading skeleton
const CourseLoadingSkeleton = ({ style }) => {
  return (
    <div className={`w-full flex flex-col gap-2 ${style} `}>
      <div className='h-[201px] w-full rounded-xl  skeleton'></div>
      <p className="h-6 w-56 rounded-xl skeleton "></p>
      <p className="h-6 w-20 rounded-xl skeleton "></p>
    </div>
  )
}



function Course_Slider({ Courses }) {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };
  return (
    <>
      {Courses?.length ? (
        <Swiper
          cssMode={true}
          navigation={true}
          pagination={pagination}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper max-h-[30rem] pt-8 px-2"

          loop={false}
          // modules={[ Pagination]}


          breakpoints={{
            670: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {Courses?.map((course, i) => (
            <SwiperSlide key={i}>
              <Course_Card course={course} Height={"h-[250px] "} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) :
        // show loading skeleton
        (<div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 ">
          <CourseLoadingSkeleton style="flex" />
          <CourseLoadingSkeleton style="hidden sm:flex" />
          <CourseLoadingSkeleton style="hidden lg:flex" />
        </div>
        )}
    </>
  )
}

export default Course_Slider