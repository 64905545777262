import React from 'react';
import CookiePolicyData from './CookiePolicyData';

const CookiePolicy = () => {
  return (
    <><div className="max-w-2xl mx-auto p-6 bg-white border rounded-md my-8">
          <h1 className="text-3xl font-bold mb-6">Cookie Policy</h1>
          {CookiePolicyData.map((point) => (
              <div key={point.id} className="mb-6">
                  <h2 className="text-xl font-bold mb-2">{point.title}</h2>
                  <ul className="list-disc pl-6">
                      {point.content.map((item, index) => (
                          <li key={index}>{item}</li>
                      ))}
                  </ul>
              </div>
          ))}
      </div></>
  );
};

export default CookiePolicy;
