import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';

import { Button, Layout, Menu, } from 'antd'


// icons
import * as Icons from "react-icons/vsc"
import { MdOutlineDarkMode, MdLogout, MdOutlineLightMode ,MdOutlineClose } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineMenuFold } from "react-icons/ai";
import { VscAccount } from "react-icons/vsc";

import { sidebarLinks } from '../../../data/dashboard-links';
import { setDarkTheme } from '../../../slices/profileSlice';
import { logout } from '../../../services/operations/authAPI';
import ConfirmationModal from '../../common/ConfirmationModal';


const { Header, Sider } = Layout;

const Sidebar2 = () => {

    const { user, darkTheme } = useSelector(state => state.profile)
    const [collapsed, setCollapsed] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(null)
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const matchRoute = (route) => {
        return matchPath({ path: route }, location.pathname)
    }

    // Collapse the sidebar on mobile when a menu item is clicked
    const handleMenuItemClick = () => {
        if (window.innerWidth < 768) {
            setCollapsed(true);
        }
    };

    // to handle sidebar collapse in mobile
    useEffect(() => {
        const handleSidebarCollapsed = () => {
            setCollapsed(window.innerWidth < 768);
        }

        handleSidebarCollapsed();
        window.addEventListener('resize', handleSidebarCollapsed)
        return () => { window.removeEventListener('resize', handleSidebarCollapsed) }

    }, [])


    return (
        <Layout>
            <Sider
                collapsed={collapsed}
                collapsible
                trigger={null}
                theme={darkTheme ? 'dark' : 'light'}
                className='flex flex-col h-[calc(100vh-3.5rem)] w-0'
            >
                <div className="flex flex-col justify-start h-full">

                <Menu 
                    theme={darkTheme ? 'dark' : 'light'}
                    className='w-full' 
                    onClick={() => setCollapsed(prev => !prev)}>
                    <Menu.Item className='px-5'
                        
                            
                            icon={collapsed ? < AiOutlineMenuFold/> : <MdOutlineClose />}   >
                            
                        
                    </Menu.Item>
                </Menu>

                    <Menu
                        theme={darkTheme ? 'dark' : 'light'}
                        mode='inline'
                        className='flex flex-col  '
                    >

                        {/* user profile  */}
                        {/* <Menu.Item
                            key={'profile'}
                            onClick={handleMenuItemClick}
                            className={` ${matchRoute('/dashboard/my-profile')
                                ? "bg-yellow-800 text-yellow-50"
                                : "bg-opacity-0 text-richblack-300"
                                } relative font-medium flex justify-center items-center text-md`}
                        >
                            <Link to={'/dashboard/my-profile'} className='flex items-center gap-2'>
                                <img
                                    src={user?.image}
                                    className='w-6 h-6 object-contain rounded-full '
                                />
                                My Profile
                            </Link>
                        </Menu.Item> */}

                        {/* all links */}
                        {sidebarLinks.map((link) => {
                            if (link.type && user.accountType !== link.type) return null;
                            const Icon = Icons[link.icon]
                            return (
                                <Menu.Item
                                    key={link.name}
                                    icon={<Icon />}
                                    onClick={handleMenuItemClick}
                                    className={` ${matchRoute(link.path)
                                        ? "bg-yellow-800 text-yellow-50"
                                        : "bg-opacity-0 text-richblack-300"
                                        }  relative font-medium flex justify-center items-center text-md`}
                                >
                                    <Link to={link.path}>
                                        {link.name}
                                    </Link>

                                </Menu.Item>

                            )
                        })}
                    </Menu>

                    <Menu
                        theme={darkTheme ? 'white' : 'light'}
                        
                    >
                        {/* theme toggle button */}
                        <Menu.Item
                            onClick={() => dispatch(setDarkTheme(!darkTheme))}
                            icon={darkTheme ? <MdOutlineLightMode /> : <MdOutlineDarkMode />}
                            className='text-white font-[16px] w-[64px] h-[64px]'
                        >
                            {darkTheme ? 'Light' : 'Dark'}
                        </Menu.Item>

                        {/* logout button */}
                        <Menu.Item
                            icon={<MdLogout />}
                            className={` ${matchRoute('logout')
                                ? "bg-yellow-800 text-yellow-50"
                                : "bg-opacity-0 text-richblack-300"
                                } relative font-medium flex justify-center items-center text-md `}
                            onClick={() =>
                                setConfirmationModal({
                                    text1: "Are you sure?",
                                    text2: "You will be logged out of your account.",
                                    btn1Text: "Logout",
                                    btn2Text: "Cancel",
                                    btn1Handler: () => dispatch(logout(navigate)),
                                    btn2Handler: () => setConfirmationModal(null),
                                })
                            }
                        >
                            Log out
                        </Menu.Item>
                    </Menu>
                </div>
            </Sider>

            {/* Collapsed button at header */}
            

            {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
        </Layout>
    )
}

export default Sidebar2
