import React, { useState } from 'react';
import TermsUse from './TermsUse'; 

const PrivacyPolicy = () => {
  const [selectedPoint, setSelectedPoint] = useState(1); // Set the initial state to the ID of the first point

  const handlePointClick = (pointId) => {
    setSelectedPoint(pointId);
  };

  return (
    <div className="flex w-full">
      {/* Left Column - Main Points */}
      <div className="w-1/4 bg-[#051226] p-4 overflow-y-auto h-screen">
        {TermsUse.map((point) => (
          <div
            key={point.id}
            className={`mb-4 cursor-pointer hover:underline ${
              selectedPoint === point.id ? 'font-bold text-white' : 'text-white'
            }`}
            onClick={() => handlePointClick(point.id)}
          >
            {point.title}
          </div>
        ))}
      </div>

      {/* Right Column - Documentation */}
      <div className="w-3/4 p-4 overflow-y-auto h-screen text-white">
        {/* Content based on the selected point */}
        {selectedPoint !== null && (
          <>
            <h2 className="text-2xl font-bold mb-4">
              {TermsUse[selectedPoint - 1].title}
            </h2>
            {TermsUse[selectedPoint - 1].content.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
