import React from 'react';

const Terms = () => {
    return (
        <><div className="max-w-2xl mx-auto p-6 bg-white border rounded-md my-8">
            <h1 className="text-3xl font-bold mb-6">Terms of Use</h1>

            <section>
                <h2 className="text-xl font-bold mb-4">1. Accounts</h2>
                <p>
                    You need an account for most activities on our platform. Keep your password safe, as you are responsible for all
                    activity associated with your account. If you suspect unauthorized access, please contact our Support Team.
                </p>
                <p>
                    You must have reached the age of consent for online services in your country to use our E-Learning platform.
                    Users below the age of consent may not create an account and should seek parental guidance.
                </p>
                <p>
                    Account termination is possible at any time, following the outlined steps. Refer to our Privacy Policy for
                    details on what happens when you terminate your account.
                </p>
            </section>

            <section>
                <h2 className="text-xl font-bold mb-4">2. Content Enrollment and Lifetime Access</h2>
                <p>
                    When you enroll in a course or other content, you get a license from us to view it via the E-Learning Services
                    and no other use. Don’t try to transfer or resell content in any way.
                </p>
                <p>
                    Lifetime access is generally granted upon enrollment, except when content is disabled for legal or policy
                    reasons. Revocation of access might occur in case of copyright complaints or Trust & Safety violations.
                </p>
                <p>
                    Instructors may not grant licenses to their content directly to students. Any such direct license is null and
                    void, violating these Terms.
                </p>
            </section>

            <section>
                <h2 className="text-xl font-bold mb-4">3. Payments, Credits, and Refunds</h2>
                <p>
                    Users can find detailed information about payment methods, the use of credits, and our refund policy in this
                    section.
                </p>
                <p>
                    Subscription Plans and add-on features may have specific terms regarding payments and access duration.
                </p>
                {/* Add more payment-related details */}
            </section>

            <section>
                <h2 className="text-xl font-bold mb-4">4. Content and Behavior Rules</h2>
                <p>
                    Guidelines for acceptable content and user behavior on the platform are outlined in this section. Users are
                    expected to adhere to these rules to maintain a positive learning environment.
                </p>
                <p>
                    Any violation of content and behavior rules may result in the suspension or termination of accounts.
                </p>
            </section>

        </div></>
    );
};

export default Terms;
