import React from "react";
import { useEffect, useState } from "react";
import { VscAdd } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

import { getAllInstructorDetails } from "../../../services/operations/adminAPI";
import IconBtn from "../../common/IconBtn";
import AdminCoursesTable from "./InstructorCourses/AdminCoursesTable";

function All_Instructors() {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [instructorDetails, setInstructorDetails] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      const result = await getAllInstructorDetails();
      if (result) {
        setInstructorDetails(result);
      }
    };
    fetchCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="mb-14 flex items-center justify-between text-white">
        <h1 className="text-3xl font-medium text-richblack-5 ">
          All Instructor Details
        </h1>
        <IconBtn text="Add Instructor" onclick={() => navigate("")}>
          <VscAdd />
        </IconBtn>
      </div>

      <Table className="rounded-xl border border-richblack-800">
        <Thead>
          <Tr className="flex gap-x-10 rounded-t-md border-b border-b-richblack-800 px-6 py-2">
            <Th className="flex-1 text-left text-sm font-medium uppercase text-richblack-100">
              Instructors
            </Th>

            <Th className=" ml-4 text-sm font-medium uppercase text-richblack-100">
              Status
            </Th>
            {/*
            <Th className=" text-sm font-medium uppercase text-richblack-100">
              Email
    </Th>*/}
          </Tr>
        </Thead>
        <Tbody>
          {instructorDetails &&
            instructorDetails.map((temp, index) => {
              return (
                <Tr
                  key={temp._id}
                  className="flex gap-x-10 border-b border-richblack-800 px-6 py-8"
                >
                  <Td className="flex flex-1 gap-x-5">
                    <img
                      src={temp.image}
                      alt="s"
                      className="h-[35px] w-[35px] rounded-[20%] "
                    />
                    <div className="flex flex-col justify-between">
                      <p className="text-lg font-semibold text-richblack-5">
                        <p>
                          {temp.firstName} {temp.lastName}
                        </p>
                      </p>
                      <p className="text-richblack-5">
                        <p>Email: {temp.email}</p>

                        {temp.additionalDetails.gender && (
                          <p>Gender: {temp.additionalDetails.gender}</p>
                        )}
                        {temp.additionalDetails.contactNumber && (
                          <p>
                            Contact Number:{" "}
                            {temp.additionalDetails.contactNumber}
                          </p>
                        )}
                      </p>
                    </div>
                  </Td>

                  <Td className="text-sm font-medium text-richblack-100">
                    {temp.active}
                  </Td>

                  {/*<Td className="text-sm font-medium text-richblack-100">
                    {temp.email}
                      </Td>*/}
                </Tr>
              );
            })}
          {/* <tr>
        <td class="border border-gray-300 px-4 py-2 text-red-500">Row 1, Cell 1</td>
        <td class="border border-gray-300 px-4 py-2 text-red-500">Row 1, Cell 2</td>
      </tr>
      <tr>
        <td class="border border-gray-300 px-4 py-2 text-red-500">Row 2, Cell 1</td>
        <td class="border border-gray-300 px-4 py-2 text-red-500">Row 2, Cell 2</td>
      </tr> */}
        </Tbody>
      </Table>
      {instructorDetails && false && (
        <pre className="text-white">
          {JSON.stringify(instructorDetails, null, 2)}
        </pre>
        // <AdminCoursesTable courses={courses} setCourses={setCourses} />
      )}
    </div>
  );
}

export default All_Instructors;
