import { IoHome } from "react-icons/io5";
import { PiNoteFill } from "react-icons/pi";
import { MdEmail } from "react-icons/md";
import { TbCategory } from "react-icons/tb";



export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
    icon: IoHome 
  },
  {
    title: "Catalog",
    icon: TbCategory 
    // path: '/catalog',
  },
  {
    title: "About Us",
    path: "/about",
    icon: PiNoteFill 
  },
  {
    title: "Contact Us",
    path: "/contact",
    icon: MdEmail 
  },
];
