import React, { useEffect, useRef } from 'react';
import { FaArrowRight } from "react-icons/fa";

import "./HeroSection.css"
import a from "./../../../assets/HeroSection/hero-bg.svg"
import b from "./../../../assets/HeroSection/hero-banner-1.jpg"
import c from "./../../../assets/HeroSection//hero-banner-2.jpg"
import d from "./../../../assets/HeroSection/hero-shape-1.svg"
import e from "./../../../assets/HeroSection/hero-shape-2.png"

const HeroSection = () => {

  return (
    <>
    <section className="section hero has-bg-image" id="home" aria-label="home" style={{backgroundImage: `url(${a})`}}>
        <div className="container">

          <div className="hero-content">

            <h1 className="leading-[1] font-[600] text-[4.2rem] section-title text-white">
            Elevate Your <span className="span">Expertise</span>  with Coding Skills
            </h1>

            <p className=" my-5 text-center md:text-left text-brown-5">
            Elevate skills with our ultimate program for unparalleled enhancement and mastery. <br/>Your success journey starts by just one click.
            </p>

            <a href="#" >
            <div className="mt-5 items-center group w-fit mx-auto md:mx-0 rounded-full bg-richblack-800  font-bold text-richblack-200 drop-shadow-[0_1.5px_rgba(255,255,255,0.25)] transition-all duration-200 hover:scale-95 hover:drop-shadow-none">
            <div className="flex flex-row items-center gap-2 rounded-full px-10 py-[5px] transition-all duration-200 group-hover:bg-richblack-900">
              <p className="">Find courses</p>
              <FaArrowRight />
              </div>
              </div>
            </a>

          </div>

          <figure className="hero-banner">
      <div className="img-holder one" style={{ '--width': 270, '--height': 300 }}>
        <img src={b} width="270" height="300" alt="hero banner" className="img-cover" />
      </div>

      <div className="img-holder two" style={{ '--width': 240, '--height': 370 }}>
        <img src={c} width="240" height="370" alt="hero banner" className="img-cover" />
      </div>

      <img src={d} width="380" height="190" alt="" className="shape hero-shape-1" />

      <img src={e} width="622" height="551" alt="" className="shape hero-shape-2" />
    </figure>

        </div>
      </section>
    </>)
};

export default HeroSection;