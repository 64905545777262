import React from "react";

function Admin() {
  return (
    <div className="text-white">
      <h1>Admin DashBoard</h1>
    </div>
  );
}

export default Admin;
