import React, { useState } from "react";
import axios from "axios";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";

function Compiler(prop) {
  const [code, setCode] = useState('print("Hello, World!")');
  const [output, setOutput] = useState("");
  const [loading, setLoading] = useState(false);

  const[api_key, setApi_key]=useState("5225408e51msh432db6fb17a477dp1f725bjsn9d8ece81e901")



  const fetchData = async () => {
    setLoading(true);

    const options = {
      method: "POST",
      // url: "https://online-code-compiler.p.rapidapi.com/v1/",
      // headers: {
      //   "content-type": "application/json",
      //   "X-RapidAPI-Key": "b5d58ee14dmsh9dd393fbe4957e3p1f3f97jsnd2da9b7b969d", //https://rapidapi.com/Glavier/api/online-code-compiler
      //   "X-RapidAPI-Host": "online-code-compiler.p.rapidapi.com",
      // },

      url: 'https://code-compiler10.p.rapidapi.com/',
      headers: {
        'content-type': 'application/json',
        'x-compile': 'rapidapi',
        'Content-Type': 'application/json',
        'X-RapidAPI-Key': api_key, //https://rapidapi.com/realbrain-realbrain-default/api/code-compiler10
        'X-RapidAPI-Host': 'code-compiler10.p.rapidapi.com'
      },
      data: {
        langEnum: [
          'php',
          'python',
          'c',
          'c_cpp',
          'csharp',
          'kotlin',
          'golang',
          'r',
          'java',
          'typescript',
          'nodejs',
          'ruby',
          'perl',
          'swift',
          'fortran',
          'bash'
        ],
        lang: prop.lang,
        code: code,
        input: "",
      },
    };

    try {
      const response = await axios.request(options);
      console.log(response.data);
      setOutput(response.data);
      if (api_key==="5225408e51msh432db6fb17a477dp1f725bjsn9d8ece81e901"){
        setApi_key("b5d58ee14dmsh9dd393fbe4957e3p1f3f97jsnd2da9b7b969d")
      }
      else{
        setApi_key("5225408e51msh432db6fb17a477dp1f725bjsn9d8ece81e901")
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <div className="text-white px-auto items-center text-center mb-4">{prop.lang} Online Compiler</div>

<div className="text-white flex flex-col md:flex-row">
  <div className="w-[100%] md:w-[60%] ">
    <AceEditor
      mode="python"
      theme="monokai"
      value={code}
      onChange={(value) => setCode(value)}
      fontSize={14}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      // setOptions={{
      //   enableBasicAutocompletion: true,
      //   enableLiveAutocompletion: true,
      //   enableSnippets: true,
      //   showLineNumbers: true,
      //   tabSize: 4,
      // }}
      
      style={{ width: "100%", marginLeft: "10px"}}
    />

    <br />

    <button
      onClick={fetchData}
      className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
        loading ? "opacity-50 cursor-not-allowed" : ""
      }`}
      disabled={loading}
    >
      {loading ? "Compiling..." : "Run Compiler"}
    </button>
  </div>

  <div className="md:w-[40%] md:h-[534px] pr-4">
    {loading ? (
      <div className=" items-center text-center py-auto">Loading...</div>
    ) : (
      <div className="flex flex-col">
        <div className="relative mt-5 md:mt-0 mb-5 p-2 w-full h-[500px] bg-black text-white font-mono  ">
          {output && output.output ? (
            <>
            <div className="overflow-y-auto max-h-[400px]">
                {output.output.split('\n').map((line, index) => (
                  <div key={index} className="text-green-400 ">
                    {'>>> '} <span className=" text-caribbeangreen-200"> {line}</span>
                  </div>
                ))}
              </div>
              {/*<div className="absolute inset-x-0 bottom-0">
                <div>CPU time: {output.cpuTime}<br></br> Memory: {output.memory}</div>
                <div className="flex justify-between	">
                  <div >Language: <b>{output.language?.id}</b></div>
                  <div> Version: <b>{output.language?.version}</b></div>
                  <div> Version Name: <b>{output.language?.version_name}</b></div>
                </div>
              </div>*/}
            </>
          ) : (
            <div>{">>> "} Hello, World!</div>
          )}
        </div>
      </div>
    )}
  </div>
</div>
    </>
  );
}

export default Compiler;
