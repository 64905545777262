import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';

import "./ChatBox.css";



const Ask = () => {
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [myResponseData, setResponseData] = useState({});
  const [apiIndex, setApiIndex] = useState(0);

  const [message, setMessage] = useState([
    {
      "Question": "What is Your Project Code?",
      "Answer": "**Your Project Code** is a comprehensive E-learning platform designed to elevate your technical and logical skills. Explore a diverse array of high-quality courses tailored to enrich your knowledge and proficiency. With our platform, you can embark on a journey of continuous learning, empowering yourself with the latest in technology and logic."
    },
    {
      "Question": "Why Your Project Code??",
      "Answer": "**Your Project Code** provides a centralized and versatile space for E-learning. As a platform, it offers a broad spectrum of courses, creating a unified environment where learners can access diverse content, fostering a holistic approach to technical and logical skill development."
    },
    
  ]);

  const myAllGPTApi=["5225408e51msh432db6fb17a477dp1f725bjsn9d8ece81e901", "d64694b65amsh17e02b52df716a7p15b31fjsn6cb4bdcf5db1", "b5d58ee14dmsh9dd393fbe4957e3p1f3f97jsnd2da9b7b969d", "63469b73c7msh2fdefe25175aab9p1e75b8jsnd64274239227", "ddbc24648fmshd6b7792e4fe8b77p131cdcjsnbd63b520f0c9",  "dddd619a60msh6b0a1dc400f0ec2p129bbajsn4c45edc8dc8d", "670aab37d9mshb345bd78f68d729p17ec8fjsnfee6387c0916"]

  const fetchData = async () => {
    
        
    
    if (loading) return; // Prevent multiple clicks while data is being fetched
    setLoading(true);

    const apiKey = myAllGPTApi[apiIndex];
    setApiIndex((prevIndex) => (prevIndex + 1) % myAllGPTApi.length);
    console.log(apiKey)


    const options = {
      method: 'POST',
      url: 'https://gpt-43.p.rapidapi.com/api/ask',
      headers: {
        'content-type': 'application/json',
        'X-RapidAPI-Key': apiKey, 
        'X-RapidAPI-Host': 'gpt-43.p.rapidapi.com',
      },
      data: {
        prompt: prompt,
      },
    };

    try {
      const response = await axios.request(options);
      const newResponseData = { "Question": prompt, "Answer": response.data.results.replace("#### Please log in to access GPT-4 mode. \n\n#### For more information, check out our YouPro plan here: https://you.com/plans.\n\nAnswering your question without GPT-4 mode:\n\n", "") };
      setMessage([...message, newResponseData]);
      console.log(newResponseData);
      setPrompt("");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    
  };

  useEffect(() => {
    console.log(myResponseData);
  }, [myResponseData]);

  useEffect(() => {
    console.log(message);
  }, [message]);

  // Custom anchor component to open links in a new tab
  const CustomLink = ({ children, href }) => {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" className='text-lime-400'>
        {children}
      </a>
    );
  };

  return (
    <div className='w-full text-white relative'>
      <div className="overflow-y-scroll md:p-4 md:mb-8 h-[76vh] ">
        
        {message.length ? (
          message.map((msg, index) => (
            <div key={index}>
              <div className={`message-box bg-richblack-100 p-2 w-fit rounded`}>
                <p className={'text-black'}>{msg.Question}</p>
              </div>
              <div className={`p-4 order-1 ml-14 mr-0 bg-blue-700 my-5 rounded`}>
                <p className={'text-white '} ><ReactMarkdown components={{ a: CustomLink }}>{msg.Answer}</ReactMarkdown></p>
              </div>
              
            </div>
          ))
        ) : (
          !loading && <p>You haven't asked a question</p>
        )}{loading && <><div className={`message-box bg-richblack-100 p-2 w-fit rounded`}>
                <p className={'text-black'}>{prompt}</p>
            </div>
            <div className={`p-4 order-1 ml-14 mr-0 bg-blue-700 my-5 rounded`}>
                <p className={'text-white '} ><ReactMarkdown components={{ a: CustomLink }}>Loading...</ReactMarkdown></p>
            </div></>}
        </div>

      <div className="flex items-center inset-x-0 bottom-0 h-16 md:w-full">
            <input
                type="text"
                id="prompt"
                name="message"
                placeholder="Ask Your Question Here..."
                onChange={(e) => setPrompt(e.target.value)}
                value={prompt}
                className="px-4 py-1 border text-black rounded-l-full focus:outline-none md: w-[90%]"
            />
            <button
                onClick={fetchData}
                disabled={loading}
                className="text-white px-3 py-1 bg-blue-500 rounded-r-full border-none cursor-pointer text-lg"
            >
                {loading ? 'Sending...' : 'Send'}
            </button>
        </div>
        {false && <div style={{ maxHeight: '300px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px' }}>
          <pre>{JSON.stringify(message, null, 2)}</pre>
        </div>}

    </div>
  );
};

export default Ask;
