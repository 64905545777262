import React from 'react'
import CTAButton from "../../../components/core/HomePage/Button";
import { FaArrowRight } from "react-icons/fa";
import Instructor from "../../../assets/Images/Instructor.jpg";
import a from "../../../assets/Images/Instructor/course.png";
import b from "../../../assets/Images/Instructor/creative-teaching.png";
import c from "../../../assets/Images/Instructor/knowledge-transfer.png";
import HighlightText from './HighlightText';

const InstructorSection = () => {

  const data = [
    {
      title: 'Teach your way',
      description: 'Share the course you desire, shape it according to your preferences, and retain complete control over your content at all times.',
      imageUrl: a,
    },
    {
      title: 'Inspire learners',
      description: 'Impart your expertise, guide learners in discovering their passions, acquiring fresh skills, and progressing in their professional journeys.',
      imageUrl: b,
    },
    {
      title: 'Get rewarded',
      description: 'Grow your professional connections, enhance your skills, and generate income with each paid enrollment.',
      imageUrl: c,
    },
  ];

  return (
    <div>
        <div className="flex flex-col lg:flex-row gap-20 items-center">
          <div className="lg:w-[40%]">
            <img
              src={Instructor}
              alt=""
              className="shadow-white shadow-[-20px_-20px_0_0] "
            />
          </div>
          <div className="lg:w-[60%] flex gap-10 flex-col">
            <h1 className="lg:w-[60%] text-4xl font-semibold ">
              Become an
              <HighlightText text={"instructor"} />
            </h1>

            <p className="font-medium text-[16px] text-justify w-[90%] text-richblack-300">
              Instructors from around the world teach millions of students on
              Your Project Code. We provide the tools and skills to teach what you
              love.
            </p>

            <div className="w-fit">
              <CTAButton active={true} linkto={"/signup"}>
                <div className="flex items-center gap-3">
                  Start Teaching Today
                  <FaArrowRight />
                </div>
              </CTAButton>
            </div>
            <div className="flex flex-col md:flex-row">
  {data.map((item, index) => (
    <div className="flex-col justify-center max-w-sm mx-2 my-4 md:my-0 bg-[#ffffff] rounded shadow-lg text-center" key={index}>
      <div><img className="w-32 h-32 p-3 mx-auto" src={item.imageUrl} alt={item.title} /></div>
      <div className="px-6 py-4">
        <div className="font-bold text-black text-md mb-2">{item.title}</div>
        <p className="text-brown-800 text-xs">{item.description}</p>
      </div>
    </div>
  ))}
</div>


          </div>
        </div>
    </div>
  )
}

export default InstructorSection