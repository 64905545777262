
const TermsUse = [
  {
    id: 1,
    title: 'Terms of Use',
    content: [
      <p>Terms of Use
        <br/>These Terms of Use ("Terms") were last updated on May 8, 2023. <br/>

        <br/>Please review these Terms carefully as they serve as an enforceable contract between us and contain important information about your legal rights, remedies, and obligations.<br/>

        <br/>IF YOU LIVE IN THE UNITED STATES OR CANADA, BY AGREEING TO THESE TERMS, YOU AGREE TO RESOLVE ALL DISPUTES WITH Abc IN SMALL CLAIMS COURT OR THROUGH BINDING INDIVIDUAL ARBITRATION ONLY, AND YOU WAIVE THE RIGHT TO PARTICIPATE IN ANY CLASS ACTIONS AND TO HAVE CLAIMS DECIDED BY A JURY, AS EXPLAINED IN THE DISPUTE RESOLUTION SECTION.<br/>

        <br/>Abc’s mission is to improve lives through learning. We enable anyone anywhere to create and share educational content (instructors) and to access that educational content to learn (students). We consider our marketplace model the best way to offer valuable educational content to our users. We need rules to keep our platform and services safe for you, us, and our student and instructor community. These Terms apply to all your activities on the Abc website, the Abc mobile applications, our TV applications, our APIs, and other related services (“Services”).<br/>

        <br/>If you publish a course on the Abc platform, you must also agree to the Instructor Terms. We also provide details regarding our processing of personal data of our students and instructors in our Privacy Policy. If you are using Abc as part of your employer’s Abc Business learning and development program, you can consult our Abc Business Privacy Statement. Our Instructor Terms, Privacy Policy, and other Abc policies applicable to your use of our Services are incorporated by reference into these Terms.<br/>

        <br/>Our website and apps cause communications about your browsing and app activities and app usage to be sent from you to third parties who provide services to Abc. By using our Services, you consent to these communications.</p>
    ],
  },
  {
    id: 2,
    title: 'Privacy Policy',
    content: [
      <p>Privacy Policy
        <br/>This Privacy Policy was last updated on December 21, 2023.<br/>

        <br/>Thank you for joining Abc. We at Abc (“Abc”, “we”, “us”) respect your privacy and want you to understand how we collect, use, and share data about you. This Privacy Policy covers our data collection practices and describes your rights regarding your personal data.<br/>

        <br/>Unless we link to a different policy or state otherwise, this Privacy Policy applies when you visit or use Abc websites, mobile applications, APIs, or related services (the “Services”). It also applies to prospective customers of our business and enterprise products.<br/>

        <br/>By using the Services, you agree to the terms of this Privacy Policy. You shouldn’t use the Services if you don’t agree with this Privacy Policy or any other agreement that governs your use of the Services.<br/>

        <br/>Table of Contents<br/>
        <br/>1. What Data We Get<br/>
        <br/>2. How We Get Data About You<br/>
        <br/>3. What We Use Your Data For<br/>
        <br/>4. Who We Share Your Data With<br/>
        <br/>5. Security<br/>
        <br/>6. Your Rights<br/>
        <br/>7. Jurisdiction-Specific Rules<br/>
        <br/>8. Updates & Contact Info<br/>
        <br/>1. What Data We Get<br/>
        <br/>We collect certain data from you directly, like information you enter yourself, data about your consumption of content, and data from third-party platforms you connect with Abc. We also collect some data automatically, like information about your device and what parts of our Services you interact with or spend time using. All data listed in this section is subject to the following processing activities: collecting, recording, structuring, storing, altering, retrieving, encrypting, pseudonymizing, erasing, combining, and transmitting..</p>
    ],
  },

  {
    id: 3,
    title: 'Business Privacy Statement',
    content: [
      <p>Abc Business Privacy Statement<br/>
      <br/>This Intellectual Property Policy was last updated on November 4, 2021.

      <br/>Abc is a technology platform that enables anyone anywhere to create and share educational content. We host hundreds and thousands of courses on our online learning marketplace. Our marketplace model means we don’t review or edit the content for legal issues, and we aren’t in a position to determine the legality of the content. However, Abc respects the intellectual property rights of others and expects instructors on Abc to do the same. When instructors post content on Abc, they make the promise that they have the necessary authorization or rights to use that content.

      <br/>Infringing activity is not tolerated on or through our platform.

      <br/>This policy addresses what we do in the event of copyright takedown notices from content owners and trademark takedown notices from trademark owners with respect to the content on the Abc platform. The policy also addresses what we do when Abc instructors’ courses are copied on third-party platforms without their consent.

      <br/>Copyright Takedown Notices
      <br/>How to File a Copyright Takedown Notice
      <br/>Counter-Notification
      <br/>Trademark Takedown Notices
      <br/>How to File a Trademark Takedown Notice
      <br/>Infringement of your Abc Content on Third-Party Platforms
      <br/>Designated Agent Contact Information
      <br/> Copyright Takedown Notices
      <br/> Abc’s policy is to remove content from our service when it is reported as infringing in a copyright takedown notice received from the owner of the original content. It’s also our policy to remove all content from any instructor who’s determined to be a repeat infringer (for whom Abc has received more than two valid copyright takedown notices). We reserve the right to terminate an instructor’s account at any time, including when they post content in violation of the copyrights of others.

      <br/> How to File a Copyright Takedown Notice
      <br/>If you’d like to report content on the Abc platform and if you are the owner or the designated agent of the owner of the rights to the content that you believe the content is infringing, the most efficient way is to use this copyright complaint form (form in English only). You may also submit notices of alleged copyright infringement to our designated copyright agent, identified below.

      <br/>Before you submit a copyright takedown notice, please remember these important things:

      <br/>We cannot process a copyright claim that is not submitted by the owner of the copyright or its designated agent. This is because we have no way of knowing whether the instructor who published the content you are reporting has received proper permission from the owner to use the content. We’ll ask you to provide an electronic signature to confirm that you’re the copyright owner or have authority to represent the copyright owner (including if the copyright owner is an organization). If you don’t own the content that you’re reporting, you’re welcome to reach out to the owner and provide them with Abc’s copyright complaint form.
      <br/>Knowingly submitting a false or misleading copyright takedown notice is illegal and you could be held liable and have to pay damages as a result. Abc reserves the right to seek damages from anyone who submits a notification of claimed infringement in violation of the law.
      <br/> Consider whether the use of your material in the content is “fair use.” Copyright law includes a “fair use” exception for certain uses of copyrighted content that are considered to be in the public interest. Before you submit a copyright claim, make sure that use of the copied content does not qualify as fair use. Fair use covers things like criticism, commentary, news reporting, and research. In considering whether the content’s use of your material qualifies as fair use, you should look at:
      <br/> The purpose of the use (whether the content is paid or unpaid, whether the content critiques/parodies/transforms your material)
      <br/> The type of copyrighted work being used (whether your work is factual or creative)
       <br/> The portion being used (whether the content uses small, necessary excerpts of your material or substantial portions of it)
        <br/>The effect on the market for your material (whether potential buyers would purchase the content instead of your material)
        <br/>There are types of content that aren’t protected by copyright. Copyright law doesn’t cover short phrases (like business names, book titles, and slogans), intangible concepts (like processes, ideas, and recipes), or facts. Before you submit a copyright claim, make sure that the copied content is indeed protected by copyright. If you need to report a trademark violation, please follow the steps outlined in the How to Submit a Trademark Takedown Notice section below.
        <br/>Your copyright claim has to be sufficiently substantiated for us to be able to address it. This means:
        <br/>You provide sufficient information for us to contact you, including your full legal name, an email address, physical address, and (optional) telephone number;
        <br/>If you’re filing a notice on behalf of an organization, you include the name of the organization and your relationship to the organization;
        <br/>You precisely identify the original copyrighted material or, if multiple copyrighted works are covered in your notification, you provide a sufficiently representative list of such original material (such as a URL where the material is located);
        <br/>You provide sufficient information for us to locate the reportedly infringing content on the Abc platform (the URL on our website and the exact name of the course and instructor); and
        <br/>You add a statement saying: “I declare, under penalty of perjury, that the information in this complaint is accurate and that I am the copyright owner or am authorized to act on the copyright owner's behalf and I have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law”.
        <br/>Counter-Notification
        <br/>If we receive a valid copyright takedown notice, we’ll send a copy of that notice to the instructor who posted the reported content along with a notification that: (1) the content was reported for copyright infringement and (2) we’re removing the content from the Abc platform. We’ll also attach a form that the instructor can fill in and send back to us to submit a counter-notification. If your content has been reported for copyright infringement and removed from the Abc platform, and if you believe we made a mistake or that you have permission from the owner of the reported content to use such content, then you may send us a counter-notification.

        <br/>Knowingly submitting a false or misleading counter-notification to a claim of infringement is illegal and you could be held liable and have to pay damages as a result. Abc reserves the right to seek damages from any party that submits a counter-notification of claimed infringement or counter-notification in violation of the law.

        <br/>The best way to provide us with a counter-notification is to fill in the form we provided you and send it back to the Abc designated agent or the copyright team member who notified you. To be effective, a counter-notification must be in writing and include the following information:

        <br/>Your physical or electronic signature;
        <br/>Your name, address, and email address or telephone number;
        <br/>Identification of the content that was removed and the location (URL) at which it appeared before it was removed (you can access this information from the copyright takedown notice filed against your content; we always attach a copy when we notify you);
        <br/>A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled; and
        <br/>A statement that you consent to: (i) Abc sharing your name and contact information with the claimant; (ii) receiving service of process for any legal action by the claimant or an agent of the claimant; and (ii) accepting the jurisdiction of the federal district court for the judicial district in which you reside (if in the U.S.), or if you reside outside of the U.S., the jurisdiction of the United States District Court for the Northern District of California (headquarters of Abc)..</p>
    ],
  },

  {
    id: 4,
    title: 'Affiliate Terms & Conditions',
    content: [
      <p>
        <br/>These Affiliate Terms & Conditions were last updated on April 1, 2021.

        <br/>THIS IS A LEGAL AGREEMENT BETWEEN YOU (“YOU”, “YOUR”, OR “YOURS”), AND Abc, INC., A DELAWARE CORPORATION LOCATED IN SAN FRANCISCO, CALIFORNIA, UNITED STATES OF AMERICA (“Abc”, “WE”, “US”, OR “OUR”). BY CLICKING ON THE "I ACCEPT" BUTTON AT THE END OF THESE AFFILIATE TERMS AND CONDITIONS (“AFFILIATE TERMS”) YOU ARE AGREEING THAT YOU HAVE READ AND UNDERSTAND THESE AFFILIATE TERMS AND THAT YOU AGREE TO BE LEGALLY RESPONSIBLE FOR EACH AND EVERY TERM AND CONDITION HEREIN.

        <br/>Any version of these Affiliate Terms in a language other than English is provided for convenience and You understand and agree that the English language will control if there is any conflict.

        <br/>All capitalized terms used and not otherwise defined herein shall have the meaning ascribed to them in Abc’s Terms of Use, Pricing and Promotions Policy or the Publisher Membership Agreement.

        <br/>1. Overview
        <br/>These Affiliate Terms contain the complete terms and conditions that apply to You when becoming an affiliate in Abc’s affiliate program (the “Affiliate Program”). The purpose of these Affiliate Terms is to allow You to make affiliate commissions through sales generated from Your website to Our Services in the manner set forth herein.

        <br/>2. Enrollment in the Affiliate Program
        <br/>(a) Application Completion. If You have not already done so, You need to complete an application to the Affiliate Program (the “Application”). You need to identify Your website, describe how You plan to promote Abc’s Services on Your website, and provide certain contact information. The Application can be found at https://www.Abc.com/affiliate/.

        <br/>(b) Acceptance of Your Application. If we choose to accept Your Application, You will receive an email notification confirming that Your Application has been approved. You understand and agree that We may accept or reject Your Application at Our sole discretion. Your Application will be rejected if any of the information You provide is incorrect or incomplete, if Your website promotes materials of a sexual, pornographic, violent, or defamatory nature, if You or Your website discriminate, violate any applicable law, or violate any person’s intellectual property rights, or for any other reason We may deem fit to reject Your Application.

        <br/>(c) Access to our Affiliate Program. If We have accepted Your Application, We will send You a welcome email with Your login details so that You may enter Our secure affiliate center. From this center You will be able to download Promotional Materials and qualifying links as well as access Your reports which will describe Our calculation of the affiliate commissions due to You. It is Your responsibility to keep Your username and password information secure. For purposes of clarity, Promotional Materials is defined as banners, text links, article copy, and access to data feeds.

        <br/>(d) You will ensure that your information including your email address is at all times complete, accurate and up-to-date. We may send communications to the email address associated with your account. You will be deemed to have received all notifications, approvals, and other communications sent to that email address, even if the email address associated with your account is no longer current.

        <br/>(e) You must be in good standing with the Federal Trade Commission (the “FTC”) and in compliance with all FTC guidelines. As an Affiliate, you also understand and agree that you have read and fully agree to the terms listed on the Official FTC Website. Specific requirements and examples for Abc Affiliates can be found here.

        <br/>3. Specific Obligations of Affiliates
        <br/>As a member of Our Affiliate Program, You represent, warrant, and covenant that You will:

        <br/>(a) Link to Our Services. You will implement the links, banners, and other means of linking Your website to Our Services (collectively, “Referral Links”) pursuant to the referral specifications set forth on the Affiliate Program on Rakuten Linkshare (“Referral Specifications”). On this page You will be able to download certain technical materials, including links, HTML code, banner ads, copy and other content, and any documentation for the foregoing (collectively, “Referral Materials”). When Our customers click through the Referral Links to purchase an item on the Abc site, you can receive commissions for qualifying individual marketplace transaction purchases as described in Affiliate Commissions.

        <br/>(b) Maintain Your Site: The maintenance and the updating of Your website will be Your responsibility. Abc will notify you via email of any changes to these Terms and our Referral Materials. However, as a member of Our Affiliate Program and because Our information is updated often, it will be necessary for You to update the Referral Materials on Your website to maintain consistency and accuracy between Our Services and the Referral Specifications.

        <br/>(d) Follow and Comply with All Copyright Laws: It is entirely Your responsibility to follow and comply with all applicable copyright and other laws that pertain to Your website. We will not be responsible if You use another person's copyrighted material in violation of the law.

        <br/>(e) Not to solicit Our Instructors: As a member of Our Affiliate Program, You agree not to directly or indirectly, for Yourself or on behalf of another, solicit business away from, or solicit, induce, influence, or encourage any of Our Instructors to upload their Abc Course(s) on Your websites and/or platforms, or otherwise alter, terminate or breach their contractual or other business relationship with Us.

        <br/>4. Affiliate Responsibilities
        <br/>As a member of Our Affiliate Program, You understand and agree that:

        <br/>(a) We Can Monitor Your Site: You hereby give Us the right to monitor Your website at any time to determine if You are following these Affiliate Terms, and to notify You of any changes We feel You should make to remain in compliance. Further, You must comply with any requests we make for you to take down specific content from your website. Failure to comply is a violation of these Terms and grounds for termination of Your affiliate status.

        <br/>(b) We Determine the Policies for Referred Customers: Persons who become customers of Our Services through referrals made in the Affiliate Program will be considered Our customers, at Our sole discretion. All Our terms, rules, policies, and operating procedures that apply to Our Users will apply to such referred customers. We may change Our terms, rules, policies, and operating procedures at any time, as further described in Our Terms of Use and Our other terms as We may post from time to time

        <br/>(c) You will not promote Abc through paid advertising or media buying that leads directly to the Abc website (found at www.Abc.com). You will not bid on Abc-branded keywords as an affiliate. This applies to all advertising platforms and to all affiliates unless direct approval from Abc is granted.

        <br/>(d) You will not use Our company name or variations of Our company name in your Domain Names or Social Media pages: You may not register or purchase domain names that include Our company’s name or any misspellings or variations of Our company name to run promotions as an affiliate. Additionally, you may not include Our Company name, variations of Our company name, or the look and feel of Our own social media pages on any social media pages (i.e. Facebook Fan Page) where You run promotions as an affiliate.

        <br/>(e) You will be Responsible for Your Website’s Content: You may not promote Our content and Our Instructors’ courses on a website that contains any form of misleading, defamatory, obscene, illegal, bigoted, pornographic or any other content deemed offensive by Us.

        <br/>(f) You will not promote using cookie-stuffing: You may not use cookie stuffing techniques or click-generators that set the tracking cookie without the user actually clicking on the Referral Link. You will not artificially generate clicks or impressions on your site or create visits on the Abc site, whether by way of a robot or software program or otherwise.

        <br/>(g) You will not mimic Our media and content: Publishers must make sure that his or her media does not copy or resemble the look and feel of the Abc website or create the impression that Your media is part of Our company's website. You also understand that using the language found on Our pages verbatim is not allowed unless it is to describe the content found on any given course landing page.

        <br/>(h) You will be Responsible for Your use of content found on Our site. You may create Your own promotional materials using pages from our site as reference. You may also use course images and part of the text in Our pages to promote the products accurately on Your site. However, You may NOT download, copy, or use video content (free or paid), course supplementary materials (PDFs, quizzes or extra material), or lesson descriptions and upload them on Your own site(s). Violation of this provision may result in the immediate termination of Your affiliate account.

        <br/>(i) You will not use spyware, malware, virus and the like: You may not include on your site, display, or otherwise use Referral Links or other Content that uses any spyware, malware, or virus, or any software application not expressly and knowingly authorized by users prior to being downloaded or installed on their computer or other electronic device.

        <br/>(j) You will be open and honest about Your relationship to Us: You may not misrepresent or embellish the relationship between you and Abc or imply any relationship or affiliation between you and Abc or any other person or entity except as expressly permitted by this affiliate Agreement. You may not represent yourself as an agent or employee of Abc or represent that you have the authority to bind Abc to a contract.

        <br/>(k) You cannot utilize a browser extension to promote Abc or Abc courses without direct approval from Abc. All coupon codes available in the extension must be approved by Abc. You also understand and agree that your browser extension cannot allow users to upload new coupons into the extension's feed.

        <br/>(l) You will not earn commissions for free courses: Our site contains paid and free courses. Any customer You refer to Us that subsequently enrolls in a free course or uses a 'free promo code' to get a paid course for free will not be eligible to earn You a commission and that particular sale will not appear on Your affiliate account. We will terminate the account of Affiliates who only promote free courses, or Affiliates who promote primarily free courses.

        <br/>(m) You will be mindful of who You do business with: You may not use marketing practices that attract fake customers. We, in our sole discretion, will make the determination whether someone is a fake customer.

        <br/>(n) You will ensure your sub-affiliates follow all of Our terms: You have the right to work with a sub-affiliate network so long as your sub-affiliates follow Our Affiliate Terms. You understand that You are responsible for Your sub-affiliate’s compliance with these terms and that non-compliance by any of Your sub-affiliates may result in the termination of Your affiliate account.

        <br/>(o) For Abc employees who are also Abc Affiliates: You will make it clear you are a Abc employee when promoting Abc as an affiliate. As a Abc employee, You agree to clearly state in all promotional posts or promotions containing Abc affiliate links Your employee and affiliate relationship with Abc via a disclaimer. Specific requirements and examples for Abc Affiliates who are also Abc employees, can be found here.

        <br/>These Affiliate Terms will begin and become effective upon Our acceptance of Your Application.

        <br/>5. Affiliate Commissions
        <br/>(a) Eligibility: Except in jurisdictions in which such a transaction is not permitted, You are eligible to earn affiliate commissions through Referrals (as defined in section (b) below) during the term of these Affiliate Terms, according to the calculation described below.

        <br/>(b) Amount due: The exact amount of affiliate commissions due to You in any given quarter will be calculated in the following manner:

        <br/>The affiliate commission is set up in the offers available to You in Your Publisher account. A “Referral” is a paying customer that You refer from Your website to Our website using the Referral Materials in accordance with the Referral Specifications. Acceptance of a Referral as a User of Our Services shall be at Our sole discretion.

        <br/>(c) Payment of affiliate commissions will be made on a monthly basis. Payments are disbursed according to the payment method You have selected in Your affiliate account. If Your account is terminated due to violation of these Affiliate Terms, We reserve the right to withhold all future payments owed to You.

        <br/>(d) Affiliate payments are sent to you using Linkshare’s affiliate network payment system. Abc does not send payment directly to any affiliates. Affiliates are responsible for making sure they are able to accept payments from Abc's affiliate network.

        <br/>(e) Affiliates are responsible for any and all charges, fees, taxes, exchange rates, surcharges and other expenses incurred in order to receive affiliate payments. Please check with your receiving banking institution to find out if any of these apply for your account.

        <br/>(f) If We determine that payment of affiliate commissions to You in any jurisdiction is illegal under any laws, then We may reserve the right to not pay affiliate commissions for any sales made in that jurisdiction.

        <br/>(g) We may withhold Your final payment for a reasonable time to ensure that the correct amount is paid to You.

        <br/>(h) If at any time there has been no substantial activity on your account for XX years, then we will have the right to withhold any accrued fee. Further, any unpaid fees in your account may be subject to escheatment under applicable law.

        <br/>6. Licenses
        <br/>(a) You hereby grant Us a non-exclusive, non-transferable, revocable right to use Your names, titles, and logos in the advertising, marketing, promoting, and publicizing in any manner of Our rights under these Affiliate Terms. However, We are under no obligation to advertise, market, promote, or publicize.

        <br/>(b) We both agree not to use the other's proprietary materials in any manner that is disparaging or that otherwise portrays the other in a negative light. We each reserve all of our respective rights in the proprietary materials covered by this license. Either one of us may revoke this license at any time by giving the other written notice ending our engagement under these Affiliate Terms and Your Affiliate status. Other than the license granted in these Affiliate Terms, we each retain all right, title, and interest to our respective rights and no right, title, or interest is transferred to the other.
      </p>
    ],
  },

  {
    id: 5,
    title: 'Instructor Terms',
    content: [
      <p>
        <br/>hese Instructor Terms were last updated May 3, 2021.

        <br/>When you sign up to become an instructor on the Abc platform, you agree to abide by these Instructor Terms ("Terms"). These Terms cover details about the aspects of the Abc platform relevant to instructors and are incorporated by reference into our Terms of Use, the general terms that govern your use of our Services. Any capitalized terms that aren't defined in these Terms are defined as specified in the Terms of Use.

        <br/>As an instructor, you are contracting directly with Abc, Inc. (a Delaware corporation in the United States), regardless of whether another Abc subsidiary facilitates payments to you.

        <br/>1. Instructor Obligations
        <br/>As an instructor, you are responsible for all content that you post, including lectures, quizzes, coding exercises, practice tests, assignments, resources, answers, course landing page content, labs, assessments, and announcements ("Submitted Content").

        <br/>You represent and warrant that:

        <br/>you will provide and maintain accurate account information;
        <br/>you own or have the necessary licenses, rights, consents, permissions, and authority to authorize Abc to use your Submitted Content as specified in these Terms and the Terms of Use;
        <br/>your Submitted Content will not infringe or misappropriate any third party's intellectual property rights;
        <br/>you have the required qualifications, credentials, and expertise (including education, training, knowledge, and skill sets) to teach and offer the services that you offer through your Submitted Content and use of the Services; and
        <br/>you will ensure a quality of service that corresponds with the standards of your industry and instruction services in general.
        <br/>You warrant that you will not:

        <br/>  post or provide any inappropriate, offensive, racist, hateful, sexist, pornographic, false, misleading, incorrect, infringing, defamatory or libelous content or information;
        <br/>  post or transmit any unsolicited or unauthorized advertising, promotional materials, junk mail, spam, or any other form of solicitation (commercial or otherwise) through the Services or to any user;
        <br/>  use the Services for business other than providing tutoring, teaching, and instructional services to students;
        <br/>  engage in any activity that would require us to obtain licenses from or pay royalties to any third party, including the need to pay royalties for the public performance of a musical work or sound recording;
        <br/>  frame or embed the Services (such as to embed a free version of a course) or otherwise circumvent the Services;
        <br/>  impersonate another person or gain unauthorized access to another person's account;
        <br/>  interfere with or otherwise prevent other instructors from providing their services or content; or
        <br/>  abuse Abc resources, including support services.
        <br/>  2. License to Abc
        <br/>  You grant Abc the rights detailed in the Terms of Use to offer, market, and otherwise exploit your Submitted Content. This includes the right to add captions or otherwise modify Submitted Content to ensure accessibility. You also authorize Abc to sublicense these rights to your Submitted Content to third parties, including to students directly and through third parties such as resellers, distributors, affiliate sites, deal sites, and paid advertising on third-party platforms.

        <br/>  Unless otherwise agreed (including within our Promotions Policy), you have the right to remove all or any portion of your Submitted Content from the Services at any time. Except as otherwise agreed, Abc's right to sublicense the rights in this section will terminate with respect to new users 60 days after the Submitted Content's removal. However, (1) rights given to students before the Submitted Content's removal will continue in accordance with the terms of those licenses (including any grants of lifetime access) and (2) Abc's right to use such Submitted Content for marketing purposes shall survive termination.

        <br/>  We may record and use all or any part of your Submitted Content for quality control and for delivering, marketing, promoting, demonstrating, or operating the Services. You grant Abc permission to use your name, likeness, voice, and image in connection with offering, delivering, marketing, promoting, demonstrating, and selling the Services, your Submitted Content, or Abc's content, and you waive any rights of privacy, publicity, or other rights of a similar nature, to the extent permissible under applicable law.

        <br/>  3. Trust & Safety
        <br/>  3.1 Trust & Safety Policies
        <br/>  You agree to abide by Abc's Trust & Safety policies, Restricted Topics policy, and other content quality standards or policies prescribed by Abc from time to time. You should check these policies periodically to ensure that you comply with any updates to them. You understand that your use of the Services is subject to Abc's approval, which we may grant or deny at our sole discretion.

        <br/>  We reserve the right to remove content, suspend payouts, and/or ban instructors for any reason at any time, without prior notice, including in cases where:

        <br/>  an instructor or content does not comply with our policies or legal terms (including the Terms of Use);
        <br/>  content falls below our quality standards or has a negative impact on the student experience;
        <br/>  an instructor engages in behavior that might reflect unfavorably on Abc or bring Abc into public disrepute, contempt, scandal, or ridicule;
        <br/>  an instructor engages the services of a marketer or other business partner who violates Abc's policies;
        <br/>  an instructor uses the Services in a way that constitutes unfair competition, such as promotion of their off-site business in a way that violates Abc's policies; or
        <br/>  as determined by Abc in its sole discretion.
        <br/>  3.2 Co-Instructors and Teaching Assistants
        <br/>  The Abc platform allows you to add other users as co-instructors or teaching assistants for Submitted Content that you manage, and you are required to comply with our Co-Instructor Relationship Rules and Guidelines when taking such actions. By adding a co-instructor or teaching assistant, you understand that you are authorizing them to take certain actions that affect your Abc account and Submitted Content. Violations of Abc's terms and policies by your co-instructor or teaching assistant may also impact your Abc account and Submitted Content. Abc is not able to advise on any questions or mediate any disputes between you and such users. If your co-instructors have an assigned revenue share, their share will be paid out of your earned revenue share based on the ratios you have specified in your Course Management settings as of the date of the purchase.
      </p>
    ],
  },
  {
    id: 6,
    title: 'Business Leadership Academy Terms & Conditions',
    content: [
<p>
        <br/>These terms and conditions were last updated on October 18, 2023.

        <br/>The terms and conditions below apply to Customer’s use of Abc’s platform, Abc Business Leadership Academy (“Abc Business Leadership Academy”), as described below. All terms not defined herein have the definitions ascribed to them in the Master Services Agreement (“Agreement”) between Abc and Customer.

        <br/>Abc Business Leadership Academy Offering. By purchasing access to Abc Business Leadership Academy, as specified in the relevant Order Form, Customer will receive licenses to access specific learning content (the “Cohort Licenses”) accessible through an online learning platform (the “Abc Business Leadership Academy Platform”) that includes Cohort Services (as defined below).

        <br/>Abc Business Leadership Academy Platform. Customer will be provided access to the Abc Business Leadership Academy Platform, as detailed in the applicable Order Form, where Users may access the specific cohort learning content (“Abc Business Leadership Academy Content”) and/or any Customer proprietary content, materials, and information (“Customer IP”). Customer may purchase access for additional Abc Business Leadership Academy Platform Users (in excess of those in the applicable Order Form), subject to an additional fee and the terms and conditions set forth herein.

        <br/>Cohort Licenses. Access to Abc Business Leadership Academy Content is limited to Users with Cohort Licenses and is available for the duration of an applicable cohort program (or such other duration agreed between the Parties), each of which shall consist of a minimum of 25 participants (the “Cohort Program”). Customer will notify Abc at least a minimum of six weeks prior to the launch of a Cohort Program. To the extent a Cohort Program has to be canceled or rescheduled through no fault of Abc (e.g., did not meet the required 25 participants, etc.), Abc shall not have any liability for such cancellation and may charge an additional fee to accommodate the rescheduled Cohort Program. Any request to reschedule a Cohort Program by Customer requires at least 14 days written notice.

        <br/>Additional Services. Optional third-party products (“Vendor Add-ons”) or additional support services (“Cohort Services”) may be included with Abc Business Leadership Academy. The availability of the Vendor Add-ons and/or Cohort Services is included with Customer’s Cohort License at an additional cost per User, which shall be set forth in an Order Form. Cohort Services are not available for Customer proprietary content. Any additional services requested by Customer will be separately scoped at an additional cost.

        <br/>Customer IP. Customer represents and warrants that Customer has all right, title and interest in and to all Customer IP provided to Abc to perform the Services set forth in the applicable Order Form and shall defend, indemnify, and hold harmless Abc and its Affiliates, subsidiaries, directors, officers, employees, and agents with respect to any claims against Abc regarding such Customer IP.

        <br/>Restrictions on Use of Licenses. Each Cohort License, once assigned to a User, is unique to such User and may not be assigned or shared with any other person via shared login or any other method. All Cohort Licenses expire on the one-year anniversary date of the issuance of such Cohort Licenses by Abc and must be renewed annually thereafter.
</p>
    ],
  },
  {
    id: 7,
    title: 'Launch Services',
    content: [
<p>
        <br/>Scope of Launch Services
        <br/>Abc will provide access to a dedicated project manager and launch program to assist Customers with the launch of the Services as further detailed in Schedule A ((“Launch Services”). The provision of the Launch Services is contingent upon the Customer purchasing subscriptions to use the Services which are provided pursuant to the Master Services Agreement ((“MSA”). Except as specifically stated herein, the MSA shall continue in full force and effect and is supplemental to and incorporated herein by reference.

        <br/>Customer Cooperation/Responsibilities
        <br/>Customer will cooperate reasonably and in good faith with Abc in performance of Launch Services by, without limitation, (a) allocating sufficient resources and timely performing any tasks reasonably necessary to enable Abc to perform its obligations under each Order Form, (b) timely delivering any materials and other obligations required under each Order Form, (c) timely responding to Abc inquiries related to the Launch Services, (d) assigning an internal project manager for each Order Form to serve as a primary point of contact for Abc, (e) actively participating in scheduled project meetings, (f) in a timely manner and at no charge to Abc, access to the appropriate and knowledgeable employees and agents, continuous administrative access to Abc, and coordination of remote online and telephonic meetings all as reasonably required by Abc, and (g) complete, accurate and timely information, data and feedback, all as reasonably required.

        <br/>Term
        <br/>This Addendum will remain in effect until the earlier of (a) completion of the Launch Services or (b) 9 months from the date of the Order Form.

        <br/>Fees and Expenses
        <br/>All Launch Services are billed and invoiced in accordance with the terms of the MSA upon receipt of a signed Order Form. All amounts for Launch Services, including Launch Service related travel and expenses, are due and payable in line with the terms of the Order Form.

        <br/>Independent Contractor
        <br/>Each Party, including employees and consultants, will at all times maintain the status of independent contractor with respect to one another.

        <br/>Intellectual Property
        <br/>Except as expressly set forth in this Addendum, neither party grants the other party, express or implied, Intellectual Property Rights.

        <br/>Warranties and Disclaimer
        <br/>Abc warrants that the Launch Services will be performed in a professional and workmanlike manner in accordance with generally accepted industry standards, in conformance with Schedule A and Abc will comply with all applicable laws in providing the Launch Services. For any breach of the above warranty, excluding Learning Program Manager, the exclusive remedy and our entire liability will be the re-performance of the applicable Launch Services. Except as is expressly provided in this section, Abc provides the Launch Services as is and makes no warranties of any kind, express or implied. Although Abc will use reasonable efforts to perform the Launch Services within the Term, Abc does not warrant or guarantee that the Launch Services will be completed within the Term.
</p>
    ],
  },
 
];

export default TermsUse;