import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/common/Navbar";
import OpenRoute from "./components/core/Auth/OpenRoute";

import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import UpdatePassword from "./pages/UpdatePassword";
import VerifyEmail from "./pages/VerifyEmail";
import About from "./pages/About";
import Contact from "./pages/Contact";
import MyProfile from "./components/core/Dashboard/MyProfile";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./components/core/Auth/PrivateRoute";
import Error from "./pages/Error";
import Settings from "./components/core/Dashboard/Settings";
import { useDispatch, useSelector } from "react-redux";
import EnrolledCourses from "./components/core/Dashboard/EnrolledCourses";
import Cart from "./components/core/Dashboard/Cart";
import { ACCOUNT_TYPE } from "./utils/constants";
import AddCourse from "./components/core/Dashboard/AddCourse";
import MyCourses from "./components/core/Dashboard/MyCourses";
import EditCourse from "./components/core/Dashboard/EditCourse";
import Catalog from "./pages/Catalog";
import CourseDetails from "./pages/CourseDetails";
import ViewCourse from "./pages/ViewCourse";
import VideoDetails from "./components/core/ViewCourse/VideoDetails";
import Instructor from "./components/core/Dashboard/Instructor";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import CookiePolicy from "./pages/cookie-policy/CookiePolicy";
import Terms from "./pages/terms/Terms";
import Admin from "./components/core/Dashboard/Admin";
import AllCourses from "./components/core/Dashboard/AllCourses";
import All_Instructors from "./components/core/Dashboard/All_Instructors";
import AllStudents from "./components/core/Dashboard/AllStudents";
import React, { useEffect, useState } from "react";
import HashLoader from "react-spinners/HashLoader";
import Compiler from "./pages/Compiler";
import Ask from "./components/common/Ask";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.profile);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false); // loading to false after 5 seconds
    }, 5000);
  }, []);
  return (
    <div className="w-screen min-h-screen bg-richblack-900 flex flex-col font-inter">
     {/*loading ? (
        <div
          style={{
            display: "flex",
            backgroundColor:  "bg-richblack-900",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <HashLoader color="#bcea0a" loading={loading} size={100} />
        </div>
      ) : ()*/
        <div>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="catalog/:catalogName" element={<Catalog />} />
            <Route path="courses/:courseId" element={<CourseDetails />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="cookie-policy" element={<CookiePolicy />} />
            <Route path="terms" element={<Terms />} />


            <Route path="python" element={<Compiler lang="python"/>} />
            <Route path="java" element={<Compiler lang="java"/>} />
            <Route path="javascript" element={<Compiler lang="typescript"/>} />
            <Route path="typescript" element={<Compiler lang="typescript"/>} />
            <Route path="kotlin" element={<Compiler lang="kotlin"/>} />
            <Route path="c++" element={<Compiler lang="c_cpp"/>} />
            <Route path="php" element={<Compiler lang="php"/>} />
            <Route path="java" element={<Compiler lang="java"/>} />
            <Route path="ruby" element={<Compiler lang="ruby"/>} />
            <Route path="r" element={<Compiler lang="r"/>} />
            <Route path="golang" element={<Compiler lang="golang"/>} />
            <Route path="perl" element={<Compiler lang="perl"/>} />
            <Route path="swift" element={<Compiler lang="swift"/>} />
            <Route path="csharp" element={<Compiler lang="csharp"/>} />
            <Route path="nodejs" element={<Compiler lang="nodejs"/>} />
            <Route path="bash" element={<Compiler lang="bash"/>} />
            <Route path="fortran" element={<Compiler lang="fortran"/>} />
            <Route path="c" element={<Compiler lang="c"/>} />
            


            <Route
              path="signup"
              element={
                <OpenRoute>
                  <Signup />
                </OpenRoute>
              }
            />
            <Route
              path="login"
              element={
                <OpenRoute>
                  <Login />
                </OpenRoute>
              }
            />

            <Route
              path="forgot-password"
              element={
                <OpenRoute>
                  <ForgotPassword />
                </OpenRoute>
              }
            />

            <Route
              path="verify-email"
              element={
                <OpenRoute>
                  <VerifyEmail />
                </OpenRoute>
              }
            />

            <Route
              path="update-password/:id"
              element={
                <OpenRoute>
                  <UpdatePassword />
                </OpenRoute>
              }
            />

            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />

            <Route
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            >
              <Route path="dashboard/my-profile" element={<MyProfile />} />

              <Route path="dashboard/Settings" element={<Settings />} />
              <Route path="dashboard/ask" element={<Ask/>} />

              {user?.accountType === ACCOUNT_TYPE.STUDENT && (
                <>
                  <Route path="dashboard/cart" element={<Cart />} />
                  <Route
                    path="dashboard/enrolled-courses"
                    element={<EnrolledCourses />}
                  />
                  

                </>
              )}

              {user?.accountType === ACCOUNT_TYPE.INSTRUCTOR && (
                <>
                  <Route path="dashboard/instructor" element={<Instructor />} />
                  <Route path="dashboard/add-course" element={<AddCourse />} />
                  <Route path="dashboard/my-courses" element={<MyCourses />} />
                  <Route
                    path="dashboard/edit-course/:courseId"
                    element={<EditCourse />}
                  />
                </>
              )}
              {user?.accountType === ACCOUNT_TYPE.ADMIN && (
                <>
                  <Route path="dashboard/admin" element={<Admin />} />
                  <Route path="dashboard/add-course" element={<AddCourse />} />
                  <Route
                    path="dashboard/all-courses"
                    element={<AllCourses />}
                  />
                  <Route
                    path="dashboard/all-instructors"
                    element={<All_Instructors/>}
                  />
                  <Route
                    path="dashboard/all-students"
                    element={<AllStudents />}
                  />
                  <Route
                    path="dashboard/edit-course/:courseId"
                    element={<EditCourse />}
                  />
                </>
              )}
            </Route>

            <Route
              element={
                <PrivateRoute>
                  <ViewCourse />
                </PrivateRoute>
              }
            >
              {user?.accountType === ACCOUNT_TYPE.STUDENT && (
                <>
                  <Route
                    path="view-course/:courseId/section/:sectionId/sub-section/:subSectionId"
                    element={<VideoDetails />}
                  />
                </>
              )}
            </Route>

            <Route path="*" element={<Error />} />
          </Routes>
        </div>
      }
    </div>
  );
}

export default App;
